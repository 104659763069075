import React from "react"
import "./Rooms.scss"
import FeatureRoom from "../../atoms/FeatureRoom/FeatureRoom"
import { documentToReactComponents } from "@contentful/rich-text-react-renderer"
import { BLOCKS } from "@contentful/rich-text-types"

const Rooms = ({content}) => {

  const {
    habitaciones,
    telefono,
    generales
  } = content

  const options = {
    renderNode: {
      [BLOCKS.PARAGRAPH]: (node, children) => <p className="section-rooms__room-description-paragraph">{children}</p>
    }
  }

  const formatMoney = (money) => {
    return money.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".");
  }

  return (
    <section className="rooms">
      <div className="rooms__wrapper">
        <div className="rooms__list">
          {habitaciones.map((room, index) => {
            return(
              <div className="rooms__room" key={index}>
                <div className="rooms__room-img">
                  <img src={room.imagenHabitacion.file.url}/>
                </div>
                <div className="rooms__room-description">
                  <h2 className="rooms__room-description-title">{room.nombreHabitacion}</h2>

                  {/*<p className="section-rooms__room-description-paragraph"> {room.description}</p>*/}
                  {documentToReactComponents(JSON.parse(room.descripcionHabitacion.raw), options)}


                  <ul className="rooms__room-description-features">
                    {room.caracteristicas.map((feature, indexFeature) => {
                      return (
                        <li>
                          <FeatureRoom key={indexFeature} feature={feature} />

                        </li>
                      )
                    })}

                  </ul>

                  <p className="rooms__room-description-price">desde <span>${formatMoney(room.precioHabitacion)}</span> por noche  </p>
                </div>

              </div>

            )
          })}


        </div>
        <div className="rooms__general-info">
          <div className="rooms__general-info-features">
            <h3 className="rooms__general-info-title">Generales</h3>
            <ul className="rooms__general-info-list">
              {generales.map((item,index) => {
                return(
                  <li key={`general-${index}`}><i className={item.icono}></i>{item.descripcion}</li>
                )
              })}
            </ul>
          </div>

          <div className="rooms__general-info-call">
            <i className="icon_set_1_icon-90"></i>
            <p>¿Necesitas ayuda? Llámanos</p>
            <a href={`tel:${telefono.replace(/ /g, "")}`}>{telefono}</a>
          </div>

        </div>
      </div>
    </section>
  )
}

export default Rooms
