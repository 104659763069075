import React from "react"
import Banner from "../../molecules/Banner/Banner"
import Rooms from "../Rooms/Rooms"
import { graphql, useStaticQuery } from "gatsby"


const ContentRooms = () => {

  const { allContentfulContenidoHabitaciones } = useStaticQuery(
    graphql`
        query Rooms_Query {
            allContentfulContenidoHabitaciones {
                nodes {
                    metaTitulo
                    metaDescription {
                        metaDescription
                    }
                    tituloBanner
                    imagenBanner {
                        fluid(maxWidth: 1980) {
                            aspectRatio
                            base64
                            sizes
                            src
                            srcSet
                        }
                        file {
                            url
                        }
                        description
                    }
                    telefono
                    generales {
                        icono
                        descripcion
                    }
                    habitaciones {
                        nombreHabitacion
                        descripcionHabitacion {
                            raw
                        }
                        caracteristicas {
                            descripcion
                            icono
                        }
                        imagenHabitacion {
                            fluid {
                                aspectRatio
                                base64
                                sizes
                                src
                                srcSet
                            }
                            description
                            file {
                                url
                            }
                        }
                        precioHabitacion
                    }
                }
            }
        }


    `
  )


  const contentInfo = allContentfulContenidoHabitaciones.nodes[0]


  return (
    <>
      <Banner title={contentInfo.tituloBanner} image={contentInfo.imagenBanner} type="fluid" />
      <Rooms content={contentInfo} />
    </>
  )
}

export default ContentRooms
