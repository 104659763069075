import React from "react"
import Layout from "../components/organisms/Layout/Layout"
import SEO from "../components/seo"
import "../styles/index.scss"
import ContentRooms from "../components/organisms/ContentRooms/ContentRooms"
import { graphql } from "gatsby"

const Habitaciones = (props) => {
  const { allContentfulContenidoHabitaciones } = props.data

  return (
    <Layout>
      <SEO
        title={allContentfulContenidoHabitaciones.nodes[0].metaTitulo}
        description={allContentfulContenidoHabitaciones.nodes[0].metaDescription.metaDescription}
      />
      <ContentRooms />
    </Layout>
  )
}

export const query = graphql`
    query Meta_Query_Rooms{
        allContentfulContenidoHabitaciones {
            nodes {
                metaTitulo
                metaDescription {
                    metaDescription
                }
            }
        }
    }
`

export default Habitaciones
