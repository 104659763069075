import React from "react"
import './FeatureRoom.scss'
const FeatureRoom = ({feature}) => {
  return (
    <div className="feature-room">
      <i className={feature.icono}> </i>
      <span>{feature.descripcion}</span>
    </div>
  )
}

export default FeatureRoom
